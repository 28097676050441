import React from "react";
import { createRoot } from 'react-dom/client';

// Globally shared dependencies for async modules
import {
    useMemo,
    useEffect,
    useLayoutEffect,
    useState,
    useContext,
    useReducer,
    useCallback,
    useRef,
    useId,
    Suspense,
    lazy,
    forwardRef
} from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useNavigate,
    useParams,
    Link
} from "react-router-dom";

import "./store";

import "./components";
import "./svgs";

import "./utils/localization";
import "./utils/useApi";
import "./utils/async";
import "./utils/events";
import "./utils/tracking";
import "./utils/hooks";

import { App } from "./App";
import { ActivityApp } from "./ActivityApp";

import "../css/critical.sass";
import { LdContentApp } from "./LdContentApp";
import { AccountSetup, UserObjectives, MeditationAssistant } from "./views";

const activityDiv = document.getElementById('explus-tools');

if(activityDiv) {
    const activityContainer = createRoot(activityDiv);
    activityContainer.render((
        <App>
            <ActivityApp />
        </App>
    ));
}

const ldContentDiv = document.querySelector('.ld-focus-content');

if(
    ldContentDiv
    // Disable for quiz pages
    && !document.body.classList.contains("single-sfwd-quiz")
) {
    const ldContentContainer = createRoot(ldContentDiv);
    ldContentContainer.render((
        <App>
            <LdContentApp originalContent={ldContentDiv.innerHTML} />
        </App>
    ));
}

const accountSetupDiv = document.getElementById('account-setup-stories');

// console.log("yes", accountSetupDiv, window.accountStoriesConfig);

if( accountSetupDiv && 'accountStoriesConfig' in window ) {
    const accountSetupContainer = createRoot(accountSetupDiv);
    // console.log(window.accountStoriesConfig);
    accountSetupContainer.render((
        <App>
            <AccountSetup config={window.accountStoriesConfig} />
        </App>
    ));
}

const userObjectivesDiv = document.getElementById('user-objectives-story');

if( userObjectivesDiv && 'userObjectivesConfig' in window ) {
    const userObjectivesContainer = createRoot(userObjectivesDiv);
    // console.log(window.accountStoriesConfig);
    userObjectivesContainer.render((
        <App loading={() => null}>
            <UserObjectives config={window.userObjectivesConfig} />
        </App>
    ));
}

const meditationAssistantDiv = document.getElementById('mediation-assistant');

if( meditationAssistantDiv && 'meditationAssistantConfig' in window ) {
    const meditationAssistantContainer = createRoot(meditationAssistantDiv);
    // console.log(window.meditationAssistantConfig);
    meditationAssistantContainer.render((
        <App loading={() => null}>
            <MeditationAssistant {...window.meditationAssistantConfig} />
        </App>
    ));
}