import React from "react";
import { Route, Routes } from "react-router-dom";

import { useAppUrlPath } from "./utils/hooks";
import { LdContent } from "./views";

export const LdContentApp : LdContentAppComponent = ({ originalContent }) => {

    const appRoute = useAppUrlPath();

    const content = <LdContent originalContent={originalContent} />;

    return (
        <Routes>
            <Route path={appRoute + "courses/:courseSlug"} element={content}>
                <Route path="lessons/:lessonSlug" element={content}>
                    <Route path="topic/:topicSlug" element={content} />
                </Route>
            </Route>
            <Route path={appRoute + "lessons/:topicSlug"} element={content}></Route>
            <Route path={appRoute + "topic/:topicSlug"} element={content}></Route>
        </Routes>
    );

}