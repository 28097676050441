import React from "react";
import { Route, Routes } from "react-router-dom";

import { activityModule, ModuleProvider, useAsyncModules } from "./utils/async";

import {
    Dashboard,
    NewEntry,
    SingleTool,
    SingleEntry,
    UpdateEntry
} from "./views";

const ActivityAppSafe = () => {
    const [{ useAppActivityUrlPath }] = useAsyncModules<[ActivityModule]>([activityModule]);

    const activityHomePath = useAppActivityUrlPath();
    const createPath = useAppActivityUrlPath(":toolKey/create");
    const editPath = useAppActivityUrlPath(":toolKey/entry/:entryId/edit");
    const viewEntry = useAppActivityUrlPath(":toolKey/entry/:entryId");
    const viewActivity = useAppActivityUrlPath(":toolKey");

    return (
        <Routes>
            <Route path={activityHomePath} element={<Dashboard />} />
            <Route path={createPath} element={<NewEntry />} />
            <Route path={editPath} element={<UpdateEntry />} />
            <Route path={viewEntry} element={<SingleEntry />} />
            <Route path={viewActivity} element={<SingleTool />} />
        </Routes>
    )
}

export const ActivityApp = () => {
    return <ModuleProvider modules={[activityModule]} dependent={ActivityAppSafe} />
}